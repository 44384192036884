@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222222;
}

body {
  font-family: "Jost", sans-serif;
  background: #f5f5f5;
}
/* DataTable.css */

.data-table th,
.data-table td {
  text-align: left;
  padding: 1px 11px 6px 2px;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

#root::-webkit-scrollbar {
  display: none;
}

.text-straight::-webkit-scrollbar {
  display: none;
}
.text-straight {
  overflow-y: auto;
  white-space: no-wrap;
}
